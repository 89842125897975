<template>
  <div>
    <div class="template-item">
      <v-img
        :src="getImageForSlug(item.slug)"
        class="elevation-5"
        contain
        eager
      >
        <div v-if="item.id === value.id" class="selected" />
      </v-img>
      <span class="text-caption">{{ item.name }}</span>
    </div>
    <div class="template-item__button text-center mt-2">
      <v-btn
        color="primary"
        :loading="item.id === value.id"
        @click="selectItemHandle"
      >
        {{ $trans("select") }}
      </v-btn>
      <v-btn
        v-if="showPreviewButton"
        class="ml-2"
        color="secondary"
        @click="openItemHandle"
      >
        {{ $trans("preview") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TemplateItem",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    showPreviewButton() {
      return this.item.type === "web";
    },
  },
  methods: {
    selectItemHandle() {
      this.$emit("change", this.item);
    },
    openItemHandle() {
      window.open(`https://${this.item.slug}.calendesk.com`, "_blank");
    },
    getImageForSlug(slug) {
      let locale = this.configuration.language;

      if (locale !== "pl" && locale !== "en") {
        locale = "en";
      }

      return require(`@/assets/${slug}_${locale}.webp`);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  display: flex;
  flex-direction: column;
}

.v-image {
  position: relative;
  border-radius: 0.5rem;
}

.selected {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 5px solid var(--v-blue-base);
  border-radius: 0.5rem;
}

span {
  text-align: center;
  color: var(--v-light-base);
  margin-top: 0.75rem;
}

.template-item__button {
}
</style>
